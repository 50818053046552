import './App.css';
import './fonts.css';

function Paelon() {
  return (
    <>
    
        

        <div class='text-left bgothm text-m'>
        <h1 class='text-4xl'><strong>Paelon</strong></h1>
    <p class='text-4xl'>Blog for the Web3 Digital PoS System + Payment Processor</p>
        <p>Contact: &nbsp; abdul@paelon.com &nbsp;  paul@paelon.com   </p>
        
        
        <p></p>

        <br />
        <p><strong>[1] Project Start: November 30, 2023</strong></p>
        <ul>
          <li>Rasberry Pi 4/5 + Linux OS + QT (Crossplatform GUI)</li>
        </ul>
        <p><strong>[2] Project Shift to Cardless PoS System: December 5, 2023</strong></p>
        <ul>
          <li>Mobile Payments</li>
          <li>QR Code Payments</li>
          <li>NFC Technology</li>
          <li>**Online/Virtual Cards</li>
          <li>Contactless Cards</li>
          <li>Mobile Wallets</li>
        </ul>
        <p><strong>[3] Paelon Psark: December 6, 2023</strong></p>
        <ul>
          <li>Digital Credit Card on Blockchain</li>
          <li>Converts USD to USDC, removing fees associated with transactions for business owners</li>
          <li>Release estimated after commercial release of Paelon Home Pylon</li>
        </ul>
        <p><strong>[4] Payment Gateway Construction: December 10, 2023</strong></p>
        <ul>
          <li>1. Data Collection</li>
          <li>2. Transaction Processing</li>
          <li>3. Communication Channel</li>
          <li>4. Security</li>
        </ul>
        <p><strong>[5] Recent Findings: December 17, 2023</strong></p>
        <ul>
          <li>Once you collect customer data, you have to communicate with the acquirers, not the payment network</li>
          <li>Acquirers are banks like BofA, Chase, Wells Fargo, etc</li>
          <li>Currently discovering whether platforms like Visa & Mastercard will allow us to not have to directly communicate with acquiring banks</li>
        </ul>

    </div>
        </>
    
    
  );
}

export default Paelon;
