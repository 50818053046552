// Sample authentication function in config.js
const authenticateUser = (username, password) => {
    // Replace these values with your actual username and password
    const validUsername = 'your_username';
    const validPassword = 'your_password';
  
    return username === validUsername && password === validPassword;
  };

const pw = 'c17'

export default pw
  