import React from "react";
import './fonts.css'

const Home = () => {
    return (
        <>
            <div className="text-3xl font-semibold mb-4">
                <h1>Abdul Alhusaini</h1>
            </div>
                <div className="text-l">
                    <h1>abdulgo@aol.com</h1>
                </div>
        </>
        
    )
}

export default Home